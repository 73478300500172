:root {
  --bg-color: white;
  --text-color: black;
  --splash-color: black;
  --splash-text-color: white;
}

.App {
  text-align: center;
  color: var(--text-color);
}

.App-header {
  background-color: var(--bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header-subtitle {
  margin-top: -25px;
  background-color: var(--splash-color);
  color: var(--splash-text-color);
  font-weight: bold;
  padding: 15px 0px;
  width: 100%;
}

.App-header-subtitle small {
  display: block;
  padding: 0px 15px;
}

.App-header-contact {
  padding: 10px 0px;
  font-size: 16px;
}

.App-header-contact a {
  color: var(--text-color);
}

.Calendar-container {
  position: relative;
  height: 0;
  width: 90vw;
  min-height: 70vh;
}

.Calendar-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
